.cvc-about-1
    .main-content
        .item
            @media (min-width: 1025px)
                .box-text,.box-img
                    padding-left: 0!important
                    padding-right: 0!important
        .item
            
            .image 
                img 
                    width: 100%
            .box-text
                display: flex 
                align-items: center
            .content
                
                padding-bottom: 1rem
                margin: 0 
                h3
                    color: #ce1b22
                    font-size: 3.6rem
                    text-transform: uppercase
                    line-height: 1
                    margin-bottom: 2rem
                    font-weight: 400
                p
                    color: #666666
                    font-size: 1.6rem
                    line-height: 2
                    font-weight: 300
                .list
                    margin-top: 20px
                    list-style: none
                    li 
                        display: flex
                        list-style: none
                        align-items: center
                        margin-bottom: 3rem
                        .left 
                            max-width: 8.5rem
                            flex: 0 0 8.5rem
                        .img 
                            width: 6.5rem
                            height: 6.5rem 
                            border-radius: 50%
                            border: 2px solid #e3e3e3
                            img 
                                width: 100%
                                height: 100%
                                object-fit: scale-down
                        .if
                            h4
                                display: flex
                                color: #666666 
                                font-size: 1.4rem
                            p 
                                font-size: 1.4rem
                                color: #666666
                                font-weight: 600
                                font-weight: 300
            &--2 
                padding-top: 8rem
                flex-direction: row-reverse
                .box-text
                    border-top: 1px solid #e3e3e3
                    .content 
                        max-height: 50rem
                        overflow-y: auto
                        &::-webkit-scrollbar
                            width: 3px
                        &::-webkit-scrollbar-track
                            background: #eee
                        &::-webkit-scrollbar-thumb
                            background: #ce1b22
                    
                .box-img
                    .image 
                        height: 70rem 
                        width: auto 
                        img 
                            width: 100%
                            height: 100%
                            object-fit: cover
                        @media (max-width: 768px)
                            height: auto 
                            width: 100%
            &--3 
                .box-img 
                    .image 
                        height: 731px
                        width: 100%
                        img 
                            width: 100%
                            height: 100%
                            object-fit: cover
            &--4 
                flex-direction: row-reverse
                .box-text
                    border-bottom: 1px solid #e3e3e3
                .content 
                    p 
                        text-transform: uppercase
                        font-size: 2.4rem
                        font-weight: 500
                .box-img 
                    .image
                        height: 60rem 
                        width: auto
                        img
                            width: 100%
                            height: 100%
                            object-fit: cover
                        @media (max-width: 768px)
                            height: auto 
                            width: 100%
            &--1 
                padding-top: 8rem
                @media (max-width: 768px)
                    padding-top: 6rem
                .image 
                    width: 100%
                    height: auto 
                    img 
                        width: 100%
                        height: 100% 
                        object-fit: scale-down
                h3
                    color: #333333!important
                    font-size: 3.6rem 
                    span 
                        font-weight: 700
                p 
                    padding-bottom: 4rem
                    font-weight: 300
                .list-sl
                    list-style: none
                    padding-bottom: 4rem
                    li 
                        position: relative
                        padding-left: 20px
                        line-height: 2
                        color: #666666
                        font-weight: 300
                        &:after 
                            content: url('/Data/Sites/1/media/ic-6.png')
                            position: absolute 
                            display: block
                            left: 0px
                            top: 0
                .content
                    max-height: 47rem
                    overflow-y: auto
                    margin: 3rem
                    padding: 0!important
                    &::-webkit-scrollbar
                        width: 3px
                    &::-webkit-scrollbar-track
                        background: #eee
                    &::-webkit-scrollbar-thumb
                        background: #ce1b22
                    @media screen and(max-width:1200px)
                        padding: 30px 20px
                    @media screen and(max-width:576px)
                        padding: 20px  0
                        background: none
                    .first-child
                        font-weight: 600!important
            .briefcontent
                font-size: 1.6rem
                color: #666666
            .image
                min-height: 40rem
                width: 100%
                height: 100%
                position: relative
                overflow: hidden
                margin-left: 0 !important
                img
                    width: 100%
                    position: absolute
                    height: 100%
                    
                    min-height: 40rem
            ul 
                padding-left: 2rem
                li 
                    list-style-type: none
                    position: relative
                    &:before
                        font-family: 'Material Design Icons'
                        content: '\f764'
                        position: absolute
                        top: .6rem
                        left: -2rem
                        color: #999999
                        font-size: 1.2rem

            &--2,&--4,&--3
                .content
                    max-width: 62rem
                    flex: 0 0 62rem
                    @media (max-width: 1200px)
                        flex: 0 0 100%
                        max-width: 100%
                .box-text
                    display: flex
                    justify-content: flex-end
            &--3
                .content
                    
                    max-width: 62rem
                    flex: 0 0 62rem
                    padding: 9rem 7rem!important
                    max-height: 50rem
                    overflow-y: auto
                    &::-webkit-scrollbar
                        width: 3px
                    &::-webkit-scrollbar-track
                        background: #eee
                    &::-webkit-scrollbar-thumb
                        background: #ce1b22
                    
                    @media (max-width: 1200px)
                        flex: 0 0 100%
                        max-width: 100%
                    @media (max-width: 1025px)
                        padding: 3rem!important
                    p 
                        font-weight: 300
                    .list 
                        .if 
                            p 
                                font-weight: 300!important
                .box-text
                    display: flex
                    justify-content: flex-start
    @media(min-width: 375px)
        .main-content
            .item
                .content
                    padding: 3rem
                    h3 
                        font-size: 2.5rem
                .briefcontent
                    font-size: 1.8rem
            .col-md-6
                position: relative
                &:before
                    content: ''
                    position: absolute
                    top: 0
                    left: 10px
                    background: #ffffff
                    width: calc(100% + 30px)
                    height: 100%
                    box-shadow: 0 0 2rem 0px rgba(0,0,0,0.1)
                    z-index: -1
                    pointer-events: none
                &:last-child
                    &::before
                        display: none
    

    @media(min-width: 1025px)
        .main-content
            .item
                .content
                    padding: 5rem 3rem
                    h3 
                        font-size: 3.6rem
                .briefcontent
                    font-size: 1.8rem
                &:nth-child(2n)
                    .col-md-6
                        &:first-child
                            order: 2
                            &::before
                                right: 1rem
                                left: initial
                        &:last-child
                            order: 1
                &--2 
                    .content 
                        padding-right: 12rem