.home-news
    padding: 7rem 0
    h1 
        text-align: center
        color: #333333
    .slider 
        position: relative
        .swiper-container 
            .swiper-slide:nth-child(1)
                width: 60rem!important
                @media (max-width: 768px)
                    width: inherit!important
                    .item 
                        .img 
                            display: none
            .swiper-slide:not(:nth-child(1))
                width: 30rem!important
                @media (max-width: 768px)
                    width: inherit!important
            .swiper-slide 
                .item
                    .content
                        padding: 3rem 2rem
                    time 
                        font-size: 1.6rem 
                        color: #999999
                        display: flex
                        padding-bottom: 2rem
                    .title 
                        font-size: 1.8rem 
                        color: #0e76bc
                        text-transform: uppercase
                        font-weight: 600
                        padding-bottom: 2rem
                        overflow: hidden
                        text-overflow: ellipsis
                        line-height: 25px
                        -webkit-line-clamp: 3
                        height: 7.5rem
                        display: -webkit-box
                        -webkit-box-orient: vertical
                    .img 
                        img 
                            width: 100%
                            height: 100%
                            object-fit: cover
                    p 
                        font-size: 1.5rem 
                        color: #666666
                        margin-bottom: 5rem
                        overflow: hidden
                        text-overflow: ellipsis
                        line-height: 25px
                        -webkit-line-clamp: 4
                        height: 10rem
                        display: -webkit-box
                        -webkit-box-orient: vertical
                    &--1 
                        display: flex
                        max-width: 100%
                        flex: 0 0 100%
                        background: #f5f5f5
                        .content 
                            background: #f5f5f5
                        .img 
                            flex: 0 0 50%
                            max-width: 50%
                            width: auto
                            img 
                                width: 100%
                                height: 100%
                                object-fit: cover
                        .btn-view-more  
                            &:hover 
                                a
                                    box-shadow: 0 5px 5px 0 rgba(0,0,0,.2)
                .item:not(.item--1)
                    position: relative
                    // &:after 
                    //     content: ''
                    //     position: absolute 
                    //     width: 100%
                    //     height: 100%
                    //     left: 0 
                    //     top: 0
                    //     background: rgba(#3d6596,.7)
                    .img
                        width: auto 
                        height: 37.5rem
                        display: block
                        img 
                            width: 100%
                            height: 100%
                            object-fit: scale-cover
                    .content 
                        position: absolute 
                        background: hsla(213, 43%, 34%, 0.6)
                        z-index: 9
                        left: 0 
                        top: 0 
                        height: auto
                        p 
                            visibility: hidden
                        .title 
                            color: #ffffff
                            font-size: 1.8rem 
                            text-transform: uppercase
                            font-weight: 600
                            padding-bottom: 2rem
                            overflow: hidden
                            text-overflow: ellipsis
                            line-height: 25px
                            -webkit-line-clamp: 3
                            height: 7.5rem
                            display: -webkit-box
                            -webkit-box-orient: vertical
                        time 
                            color: #ffffff
                            
                        .btn-view-more 
                            a 
                                background: none
                                border: 1px solid #ffffff
                                transition: .3s
                            &:hover 
                                a 
                                    background: #ce1b22
                                    color: #ffffff
                                    border: 1px solid #ce1b22
        .swiper-pagination
            display: flex
            justify-content: center
            bottom: -4rem
            left: 0
            right: 0
            position: absolute
            span
                width: 1.2rem
                height: 1.2rem
                background: #f7941d
                opacity: 1
                border-radius: 0
                border-radius: 50%
                position: relative
                z-index: 9
                outline: none
                margin-right: 1.4rem
                &:after 
                    content: ''
                    display: none
                    position: absolute
                    border-radius: 50%
                    bottom: 50%
                    left: 50%
                    width: 1.7rem
                    height: 1.7rem
                    background: none 
                    border: 1px solid #f7941d
                    transform: translate(-50%,50%)
                    z-index: 1
                &.swiper-pagination-bullet-active
                    &:after 
                        display: block


