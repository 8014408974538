.doitac
    padding: 70px 0
    position: relative
    z-index: 2
    h1
        text-align: center
        color: #333333
    .slider
        .swiper-wrapper 
            padding: 10px
        .doitac-slider
            position: relative
            .swiper-btnnext
                margin-left: 1rem
                position: absolute
                top: 3rem
                right: 2rem
            .swiper-btnprev
                margin-right: 2rem
                position: absolute
                top: 3rem
                right: 2rem
        .khachhang-slider
            position: relative
            .swiper-btn-next
                position: absolute
                top: 3rem
                right: 2rem
            .swiper-btn-prev
                margin-right: 2rem
                position: absolute
                top: 3rem
                right: 2rem
        h4 
            padding: 3rem 0
            position: relative
            font-size: 2.4rem
            text-transform: uppercase
            color: #0e76bc
            font-weight: 400
            &:after
                position: absolute
                bottom: 2rem
                content: ''
                display: block
                width: 100%
                height: 1px
                background: #ebebeb
        .item
            background: #ffffff
            width: 15rem
            height: 7rem
            display: flex
            justify-content: center
            align-items: center
            box-shadow: 0 0 5px 0px rgba(0 ,0, 0,.2)
            padding: 8px
            img
                width: 100%
                height: 100%
                object-fit: scale-down
@media (max-width: 768px)
    .doitac
        padding: 4rem
        .slider
            .doitac-slider
                flex: 0 0 100%
                max-width: 100%
            .khachhang-slider
                flex: 0 0 100%
                max-width: 100%
            .doitac-slider,.khachhang-slider
                .swiper-slide
                    display: flex
                    justify-content: center
