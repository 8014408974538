header
	&.fixed
		position: fixed
		width: 100%
		z-index: 10
		.top-header
			display: none
		.bottom-header
			.logo
				overflow: hidden
				img
					transform: translateY(-5px)
					max-width: 100px
		.mobile-menu
			top: 70px 
		.back-drop
			top: 70px
	&.hide
        -webkit-transform: translateY(-100%)
        -ms-transform: translateY(-100%)
        transform: translateY(-100%)
	.top-header
		padding: 10px
		background: #e1e1e1
		@media (max-width: 1200px)
			display: none
			img 
				max-width: 20px
		.row
			align-items: center
		// @media (max-width: 1200px)
		// 	display: none
	.bottom-header
		background: white
		// box-shadow: 0 0 10px 3px rgba(0,0,0,.15)
		height: 70px
		.row
			height: 70px
			transition: all .4s cubic-bezier(.2,.5,.5,.8)
			@media (max-width: 1199.98px)
				align-items: center
				height: 70px
	.contact
		display: flex
		align-items: center
		li 
			display: inline-block
			padding: 0 30px
			border-right: 1px solid #f7941d
			@media (max-width: 1200px)
				padding: 0
				padding-right: 20px!important
				border-right: none
		.hotline,.mail
			font-size: 12px
			color: #666666
			display: flex
			align-items: center
			span 
				padding-left: 10px
		@media (max-width: 1200px)
			.Module
				width: 100%
			.ModuleContent
				display: flex
				li 
					padding: 10px 20px
					border-bottom: 1px dashed hsla(0,0%,100%,.35)
					strong
						color: #ffffff

	.search
		margin-left: auto
		.searchbox
			position: relative
			width: 300px
		@media (max-width: 1199.98px)
			padding: 10px 20px
			border-bottom: 1px dashed rgba(255,255,255,.35)
		input
			border-radius: 5px
			height: 30px
			border: 0
			background: #ffffff
			width: 100%
			padding: 0 10px
			color: #666666
			font-size: 12px
			font-style: italic
			&::placeholder
				color: #666666
				font-size: 12px
				font-style: italic
			&:focus
				outline: none
		button
			position: absolute
			top: 50%
			transform: translateY(-50%)
			height: 100%
			width: 30px
			right: 10px
			background: #f7941d
			color: #ffffff
			width: 20px
			height: 20px
			border-radius: 5px
			display: flex 
			align-items: center
			justify-content: center
			border: 0
			span
				margin-top: -1.5px
	.social
		@media (max-width: 576px)
			display: none
		ul
			display: flex
			list-style-type: none
			width: 120px
			height: 30px
			padding-left: 0
		a
			display: flex
			justify-content: center
			align-items: center
			height: 100%
			color: rgba(255,255,255,.6)

		li
			flex: 0 0 40px
			max-width: 40px
			margin-left: 20px
			@media (max-width: 1200px)
				margin-left: 0
				margin-right: 20px
			&:hover
				a
					color: #fff100
		@media (max-width: 1199.9px)
			margin-left: auto
		@media (max-width: 575.9px)
			ul
				width: 90px
			li
				max-width: 30px
				flex: 0 0 30px
		@media (max-width: 1200px)
			.Module
				width: 100%
				border-bottom: 1px dashed hsla(0,0%,100%,.35)
				padding: 10px 20px
					
	@media (max-width: 1200px)
		.Module-210
			margin-left: auto
	.language-wrapper
		position: relative
		padding: 0 10px
		user-select: none
		padding: 0 10px
		cursor: pointer
		display: flex
		z-index: 333
		justify-content: center
		align-items: center
		position: relative
		a
			transition: .4s ease-in-out all
			font-size: 12px
			color: #666666
		span
			color: #ffaa00
			white-space: nowrap
			overflow: hidden
			text-overflow: ellipsis
			max-width: 14ch
			display: block
		&:after
			content: ''
			height: 12px
			width: 1px
			position: absolute
			top: 50%
			left: 0
			transform: translateY(-50%)
			background: rgba(#fff, .2)
		.language-active
			display: flex
			cursor: pointer
			justify-content: center
			align-items: center
			height: 100%
			em
				transition: transform .3s ease-in-out
				font-size: 12px
				color: #666666
				margin-left: 5px
			p 
				font-size: 12px
				color: #666666
		.language-list
			position: absolute
			top: 150%
			left: 50%
			transform: translateX(-50%)
			transition: .6s ease-in-out all
			opacity: 0
			pointer-events: none
			background: #13293d
			// left: 0
			width: 50px
			a
				padding: 9px
				display: flex
				justify-content: center
				align-items: center
				color: #1a77b9
				font-size: 12px
				&:hover 
					color: #ffffff
		&.active
			.language-active
				em
					transform: rotate(-180deg)
			.language-list
				top: calc(100% + 15px)
				opacity: 1
				pointer-events: initial
				@media (max-width: 768px)
					top: calc(100% + 5px)
		

	.no-gutters
		align-items: center
		position: relative
	.logo
		position: absolute
		top: 5px
		left: 0
		img
			max-width: 190px
			height: auto
			transform: translateY(-50px)
		@media (max-width: 1200px)
			img
				transform: translateY(-5px)
				max-width: 100px
	.main-menu
		margin-left: auto
		transition: all .4s cubic-bezier(.2,.5,.5,.8)
		@media (max-width: 1200px)
			display: none
		.ModuleContent 
			height: 100%
		ul
			display: flex
			justify-content: space-between
			padding-left: 0
			list-style-type: none
			align-items: center
			height: inherit
			li 
				&:active
					a
						&:after
							transform: scaleX(1)
							background: #1a77b9
					a
						color: #1a77b9
				&:hover 
					a 
						color: #ec2224
						&:after 
							background: #ec2224
		a
			font-size: 16px
			text-transform: uppercase
			color: #333333
			font-weight: 500
			transition: all .4s cubic-bezier(.2,.5,.5,.8)
			@media (max-width: 1365.98px)
				font-size: 15px
				
		li
			margin-right: 25px
			a
				position: relative
				&:after
					content: ''
					display: block
					position: absolute 
					left: 50%
					margin-left: -20px
					background: #1a77b9
					height: 1px
					bottom: -5px
					width: 40px
					transform: scaleX(0)
					transform-origin: 50% 100% 0
					transition: all .2s ease-in-out
			&:hover
				a
					&:after
						transform: scaleX(1)
				a
					color: #1a77b9
			&.active
				a
					&:after
						transform: scaleX(1)
				a
					color: #1a77b9
	.mobile-toggle
		// border: 2px solid
		width: 40px
		height: 30px
		/* border-radius: 5px */
		display: flex
		justify-content: center
		align-items: center
		.button
			height: 2px
			width: 25px
			background: #1a77b9
			position: relative
			transition: all 0ms 300ms
			&:after
				content: ""
				position: absolute
				left: 0
				width: 100%
				height: 100%
				bottom: 6px
				background: #1a77b9
				transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1)
			&:before
				content: ""
				position: absolute
				left: 0
				width: 100%
				height: 100%
				top: 6px
				background: #1a77b9
				transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1)
		&.active
			.button
				background: transparent
				&:before
					top: 0
					transform: rotate(45deg)
					transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1)
				&:after
					bottom: 0
					transform: rotate(-45deg)
					transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1)
		@media (min-width: 1200px)
			display: none
		@media (max-width: 1199.9px)
			color: map-get($mau, main-b)
			font-size: 30px
	.mobile-menu
		display: none
		@media (max-width: 1199.98px)
			position: fixed
			top: 70px
			right: -340px 
			width: 340px
			background-color: #13293d
			z-index: 999
			height: calc(100vh)
			transition: all 0.5s ease
			display: block !important
			overflow-y: auto !important
			overflow-x: hidden !important
			padding: 0
			@media (max-width: 576px)
				top: 70px
			&.active
				right: 0
		.main-menu
			height: unset
			display: block
			padding: 0 20px
			ul
				align-items: flex-start
				flex-direction: column
			li
				width: 100%
			a
				color: white
				padding: 14px 0
				display: flex
				&:after 
					display: none
		
	.back-drop
		z-index: 9
		position: fixed
		top: 70px
		bottom: 0
		left: 0
		width: 100%
		transform: translateX(-100%)
		background: rgba(0,0,0,.8)
		opacity: 0
		transition: all .4s cubic-bezier(.2,.5,.5,.8)
		@media (max-width: 576px)
			top: 70px
		@media (min-width: 1200px)
			opacity: 0
		&.active
			transform: translateX(0)
			opacity: 1