.cvc-du-an
    h1 
        text-align: center
    .item
        margin-bottom: 30px
        figure
            .image
                display: flex
                justify-content: center
                align-items: center
                height: 260px
                overflow: hidden
                box-shadow: 0px 0px 1px 0px transparent
                a
                    color: #333333
                    text-align: center
                    cursor: pointer
                    img
                        max-width: 100%
                        height: 260px
                        transition: .5s all ease-in-out
            figcaption
                text-align: center
                background: #f4f4f4
                padding: 10px 
                a
                    color: #333333
                    overflow: hidden
                    text-overflow: ellipsis
                    line-height: 23px
                    -webkit-line-clamp: 2
                    height: 46px
                    display: -webkit-box
                    -webkit-box-orient: vertical
        &:hover
            figure
                .image
                    box-shadow: 0px 0px 1px 0px #a1a1a1
                    img
                        transform: scale(1.1)
                figcaption
                    a
                        color: #cd1518
.du-an-page 
    .pagination
        padding-bottom: 6rem