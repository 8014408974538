// Fontawesome icons
.fa-bars:before
	content: '\f0c9'
.fa-user:before
	content: '\f007'
.fa-shopping-cart:before
	content: '\f07a'

.fa-home:before
	content: '\f015'
.fa-clock:before
	content: '\f017'
.fa-phone-volume:before
	content: '\f2a0'
.fa-key:before
	content: "\f084"
.fa-folder-open:before
	content: "\f07c"
.fa-cog:before
	content: "\f013"
.fa-edit:before
	content: "\f044"
.fa-file:before
	content: "\f15b"
.fa-pencil:before
	content: "\f303"
.mdi-send:before 
	content: '\f48a'
.mdi-arrow-right:before
	content: '\f054'

// Font awesome - social icons
.fa-facebook-f:before
	content: '\f39e'
.fa-youtube:before
	content: '\f167'
.fa-twitter:before
	content: '\f099'
.fa-google-plus-g:before
	content: '\f0d5'
.fa-instagram:before
	content: '\f16d'
.fa-envelope:before
	content: '\f0e0'
.fa-phone-square:before
	content: '\f098'


	
// Material icons
.mdi-menu-left:before 
	content: '\f35e'
.mdi-menu-right:before
	content: '\f35f'
.mdi-chevron-down:before 
	content: '\f140'
.mdi-reload:before
	content: '\f453'
.mdi-chevron-right:before
	content: '\f142'
.mdi-chevron-left:before
	content: '\f141'
.mdi-account:before
	content: '\f004'
.mdi-magnify:before
	content: '\f349'
.mdi-home:before
	content: '\f2dc'
.mdi-close:before
	content: '\f156'
.mdi-menu:before
	content: '\f35c'
.mdi-phone:before
	content: '\f3f2'
.mdi-email:before
	content: '\f1ee'
.mdi-plus-circle:before
	content: '\f417'
.mdi-alert-circle-outline:before
	content: '\f5d6'
.mdi-calendar:before
	content: '\f0ed'
.mdi-map-marker:before
	content: '\f34e'
.mdi-chevron-double-left:before
	content: '\f13d'
.mdi-chevron-double-right:before
	content: '\f13e'
.mdi-cloud-download:before
	content: '\f162'
.mdi-briefcase:before
	content: '\f0d6'
.mdi-fax:before
	content: '\f212'
.mdi-whatsapp:before
	content: '\f5a3'
.mdi-map:before
	content: '\f34d'
.mdi-printer:before
	content: '\f42a'
.mdi-file-outline:before
	content: '\f224'
.mdi-download:before 
	content: '\f1da'
.mdi-play-circle-outline:before
	content: '\f40d'
// Linearicons icons
.lnr-chevron-up:before
	content: '\e873'
.lnr-chevron-down:before
	content: '\e874'
.lnr-chevron-left:before
	content: '\e875'
.lnr-chevron-right:before
	content: '\e876'
.lnr-picture:before
	content: '\e827'
.lnr-arrow-right:before
	content: '\e87a'
.lnr-arrow-left:before
	content: '\e879'

.lnr-download:before
	content: '\e865'
.mdi-cloud-download:before
	content: '\f162'
.lnr-download:before
	content: '\e865'
.mdi-file-outline:before
	content: '\f224'