.dq-recruitment-1
    padding: 30px 0
    @media(min-width: 1025px)
        padding: 60px 0
    .main-title
        text-align: center
    .main-content
        .item
            padding-bottom: 40px
            &:last-child
                padding-bottom: 0
            .content
                padding-bottom: 10px
                h3
                    color: #333333
                    font-size: 18px
                    font-weight: 700
                    text-transform: capitalize
                p
                    color: #333333
                    font-size: 16px
                h4
                    font-size: 18px

            .image
                height: 380px
                width: auto
                img
                    width: 100%
                    height: 100%
                    object-fit: cover
            
        // .even
        //     margin-top: 40px
        //     .content
        //         padding-bottom: 10px
        //         h3
        //             padding-top: 10px
        //             color: #cb7312
        //             font-size: 18px
                   
        //             font-weight: 700
        //             text-transform: capitalize
        //         p
        //             color: #333333
        //             font-size: 16px
        //     .image
        //         img
        //             width: 100%
    ul
        + h4
            margin-top: 30px
    @media(min-width: 768px)
        .main-content
            .item
                .col-md-6
                    padding: 0
                    .content
                        padding: 20px 30px
                        margin-right: -15px
                        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.08)
                        height: 100%
                    .image
                        margin-left: 15px
                        img
                            width: 100%
                &:nth-child(2n)
                    .col-md-6
                        &:nth-child(1)
                            order: 2
                            margin-right: 15px
                        &:nth-child(2)
                            order: 1
                            margin-left: -15px
        // .even
        //     .col-md-6
        //         padding: 0
        //         .content
        //             padding: 20px 30px
        //             margin-left: -15px
        //             height: 100%
        //             box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.08)
        //         .image
        //             margin-right: 15px
        //             img
        //                 width: 100%
    
    ul
        padding-left: 20px
        li
            list-style: none
            font-size: 18px
            color: #333333
            position: relative
            padding: 5px 0
            &:before
                content: ''
                position: absolute
                width: 7px
                height: 7px
                border-radius: 50%
                background: #999999
                left: -20px
                top: 15px
    @media(min-width: 1025px)
        .main-content
            .item
                .col-md-6
                    .image
                        height: 380px
                        width: auto
                        img
                            height: 100%
                            width: 100%
                    .content
                        padding: 50px 30px
                        h3
                            padding-bottom: 20px
                            font-size: 30px
                        p
                            font-size: 18px
.dq-recruitment-2
    padding-bottom: 60px
    .main-title
        text-align: center
    .main-table
        overflow: auto
    table 
        width: 100%
        th 
            white-space: nowrap
        th, td 
            padding: 15px
            font-size: 18px
            font-weight: 700
            color: #ffffff
            border-left: 1px solid #d1d1d1
            border-right: 1px solid #d1d1d1
            text-align: center
            &:nth-child(2)
                text-align: left
        td 
            font-size: 16px
            font-weight: 500
            color: #333333
            a
                text-decoration: none
                color: #333333
                font-size: 16px
                font-weight: 500
        thead 
            background: #ec2224
        tr
            cursor: pointer
            border-bottom: 1px solid #d1d1d1
            transition: background-color 0.1s, color 0.1s
            &:hover
                
                box-shadow: 0px 0px 8px 0px rgba(236, 23, 26, 0.25)
                td
                    border-bottom: 1px solid #ec2224
                    border-top: 1px solid #ec2224
                    color: #ec2224
                    a
                        color: #ec2224
.canhcam-job-2
    background: url(https://vmgfashion.com/Data/Sites/1/skins/default/img/job/job_3.png)
    background-size: cover
    background-position: cover cover
    -webkit-filter: grayscale(100%)
    filter: grayscale(100%)
    padding-top: 60px
    padding-bottom: 60px
    color: #fff
    text-align: center
    .title
        margin-bottom: 10px
        text-transform: uppercase
    .desc
        p
            font-size: 18px
    .link
        margin-top: 20px
        a
            display: inline-block
            border: 1px solid #fff
            color: #fff
            -webkit-transition: .5s all ease-in-out
            -o-transition: .5s all ease-in-out
            transition: .5s all ease-in-out
            text-transform: uppercase
            text-decoration: none
            padding-top: .5rem
            padding-right: 1.5rem
            padding-bottom: .5rem
            padding-left: 1.5rem