.cvc-home-hotline
    padding: 30px 0
    background: #ce1b22
    .pb-30
        padding-bottom: 30px
        &:last-child
            padding-bottom: 0
        @media(min-width: 1025px)
            padding-bottom: 0
    .title-h3 
        padding-bottom: 10px
        h3 
            color: #ffffff
            font-size: 20px
            font-weight: 500
        @media(min-width: 1025px)
            padding-bottom: 15px
    .main-item
    .item
        padding-bottom: 20px
        &:last-child
            padding-bottom: 0
    ul 
        list-style-type: none
        li 
            display: flex
            padding-bottom: 5px
            em 
                color: #ffffff
                font-size: 16px
                padding-right: 10px
            p, a 
                font-size: 16px
                color: #ffffff
                font-weight: 400