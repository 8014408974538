.home-product-1 
    padding: 7rem 0
    background: #e0e9ef
    h1 
        color: #333333
        text-align: center
    .list-item 
        display: flex
        flex: 0 0 100%
        max-width: 100%
        flex-wrap: wrap
        .item
            .img 
                width: auto 
                img 
                    width: 100%
                    height: 100%
                    object-fit: cover 
            .title 
                position: absolute
                bottom: 0 
                
                text-align: center

                width: calc(100% - 10px)

                left: 5px
                background: rgba(#000,.7)
                a 
                    padding: 2rem 0
                    text-transform: uppercase
                    display: block
                    font-size: 1.8rem
                    color: #ffffff
                    transition: all .3s ease-in-out
                    overflow: hidden
                    text-overflow: ellipsis
                    line-height: 24px
                    -webkit-line-clamp: 2
                    -webkit-box-orient: vertical
            &:hover
                .title 
                    background: #ce1b22
                    a 
                        text-decoration: underline
            &--1
                position: relative
                max-width: 50%
                flex: 0 0 50%
                padding: 0 5px
                @media (max-width: 567px)
                    max-width: 100%
                    flex: 0 0 100%
                    margin-bottom: 2rem
                    
                .title
                    a 
                        font-size: 2.4rem
                .img
                    width: auto
                    height: 60rem
                    overflow: hidden
                    @media (max-width: 768px)
                        height: 35rem
                    img 
                        width: 100%
                        height: 100%
                        object-fit: cover
                        transition: all .3s ease-in-out
            &--2
                max-width: 25%
                flex: 0 0 25%
                display: flex
                flex-direction: column
                align-items: space-between
                padding: 0 5px
                @media (max-width: 1025px)
                    max-width: 50%
                    flex: 0 0 50%
                @media (max-width: 567px)
                    max-width: 100%
                    flex: 0 0 100%
                .items:nth-child(1)
                    margin-bottom: 10px
                .img
                    height: 29.5rem
                    width: auto 
                    overflow: hidden
                    @media (max-width: 768px)
                        height: 35rem

                    img
                        width: 100%
                        height: 100%
                        object-fit: cover
                        transition: all .3s ease-in-out
                .items 
                    position: relative
                    .title 
                        position: absolute
                        bottom: 0 
                        
                        text-align: center

                        width: calc(100% - 0)

                        left: 0
                        background: rgba(#000,.7)
                        a 
                            padding: 2rem 0
                            text-transform: uppercase
                            display: block
                            font-size: 1.8rem
                            color: #ffffff
                            transition: all .3s ease-in-out
                            overflow: hidden
                            text-overflow: ellipsis
                            line-height: 24px
                            -webkit-line-clamp: 2
                            
                            -webkit-box-orient: vertical
                    &:hover 

                        .title 
                            background: #ec2224
                            a 
                                text-decoration: underline
            &--3
                position: relative
                max-width: 25%
                flex: 0 0 25%
                padding: 0 5px 
                @media (max-width: 1025px)
                    margin-top: 20px
                    max-width: 100%
                    flex: 0 0 100%
                .img
                    height: 60rem
                    @media (max-width: 768px)
                        height: 35rem
.home-product-2 
    background: #e0e9ef
    padding-bottom: 7rem
    h1 
        text-align: center 
        color: #333333
    .slider
        position: relative
    .swiper-container 
        .swiper-wrapper 
            .swiper-slide 
                .item 
                    background: #ffffff
                    .img 
                        height: 17rem
                        width: auto 
                        padding: 30px 
                        display: flex 
                        align-items: center 
                        justify-content: center
                        img
                            width: 100%
                            height: 100%
                            object-fit: scale-down
                    .title 
                        padding: 20px
                        .top 
                            padding-bottom: 2rem
                            border-bottom: 1px solid #999999
                            a
                                color: #333333
                                font-size: 1.6rem 
                                overflow: hidden
                                text-overflow: ellipsis
                                height: 4.8rem
                                display: block
                                line-height: 24px
                                -webkit-line-clamp: 2
                                -webkit-box-orient: vertical
                            p
                                color: #333333
                                text-transform: uppercase
                                font-size: 1.6rem 
                        .bottom 
                            padding-top: 2rem
                            p 
                                font-size: 1.4rem 
                                color: #999999
                                font-weight: 600
    .swiper-navigation
        width: 110%
        display: flex
        align-items: center
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        z-index: 99
        .p2-button-next,.p2-button-prev
            width: 4rem
            height: 4rem
            display: flex
            border: none
            outline: none
            display: flex
            justify-content: center
            align-items: center
            background: #ffffff
            img
                transform: .2s
            &:hover 
                background: #3d6596
                img 
                    filter: brightness(0) invert(1)
        .p2-button-next 
            margin-left: auto
        @media (max-width: 992px)
            top: 110%
            width: 95px