@-webkit-keyframes circle 
    0%
        width: 100%
        height: 100%
    100%
        width: 130%
        height: 130%
.contact-button-wrapper
    z-index: 333
    position: fixed
    bottom: 7rem
    right: 5rem
    @media (max-width: 768px)
        bottom: 3rem
        right: 3rem
        .ic 
            width: 5rem
            height: 5rem
            em 
                font-size: 2.4rem!important
        

    .ic
        width: 6.5rem
        height: 6.5rem
        background: #1a77b9
        z-index: 22
        position: relative
        color: #ffffff
        display: flex
        justify-content: center
        border-radius: 50%
        align-items: center
        font-size: 3rem
        em
            font-size: 3rem
    &:before
        content: ''
        width: 100%
        height: 100%
        border-radius: 50%
        position: absolute
        background: rgba(#1a77b9, .3)
        top: 50%
        transform: translate(-50% , -50%)
        left: 50%
        animation-duration: 2s
        // animation-timing-function: linear
        animation-name: "circle"
        animation-delay: 2s
        animation-iteration-count: infinite
    &:hover
        color: #fff