.home-banner 
    .swiper-container
        position: relative
        .swiper-wrapper
            position: relative
            .swiper-slide    
                .img 
                    height: calc(100vh - 11.6rem)
                    width: auto
                    img 
                        width: 100%
                        height: 100%
                        object-fit: cover
                @keyframes moveInLeft 
                    0% 
                        opacity: 0
                        transform: translateX(-100px)
                    80% 
                        transform: translateX(10px)
                    100% 
                        opacity: 1
                        transform: translateX(0)
                .banner-title
                    position: absolute
                    bottom: 50%
                    transform: translateY(50%)
                    left: 0
                    width: 100%
                @media (max-width: 768px)
                    .title 
                        .img 
                            max-width: 25rem!important
                        p 
                            font-size: 1.8rem!important
                .title 
                    text-align: center
                    padding: 2rem 8rem 3rem 2rem
                    border-left: 6px solid #731d28
                    background: rgba(#000,.5)
                    display: inline-block
                    animation: moveInLeft 1s ease-out
                    .img
                        max-width: 40rem
                        height: auto
                        img 
                            object-fit: fill
                            width: 100%
                            height: 100%
                        
                    p 
                        font-size: 2.6rem 
                        color: #ffffff
                        text-transform: uppercase
                        text-align: left
                        line-height: 2.5
                        white-space: nowrap

                    span
                        font-size: 3.6rem
                        color: #ffffff
                        font-weight: 300

                @media (max-width: 768px)
                    .img 
                        max-height: 45rem 
                        width: auto
                        img 
                            width: 100%
                            height: 100%
                            object-fit: cover
                    .title 
                        span 
                            font-size: 2.4rem
        .swiper-pagination
            margin-top: 3rem
            display: flex
            justify-content: center
            bottom: 3rem
            left: 0
            right: 0
            position: absolute
            @media (max-width: 480px)
                display: none
            span
                width: 1.2rem
                height: 1.2rem
                background: #f7941d
                opacity: 1
                border-radius: 0
                margin: 0 1rem
                border-radius: 50%
                position: relative
                z-index: 9
                &:after 
                    content: url('/Data/Sites/1/media/bullet.png')
                    display: none
                    position: absolute
                    bottom: 50%
                    left: 50%
                    transform: translate(-50%,57%)
                    z-index: 1
                &.swiper-pagination-bullet-active
                    &:after 
                        display: block
        
        .swiper-btn-prev
            
            position: absolute

            top: 50%
            transform: translateY(-50%)
            z-index: 99
            left: 1.5rem
            outline: none
            em 
                font-size: 34px
                color: #ffff
                transition: .5s
                &:hover 
                    color: #ac2021
        .swiper-btn-next
            
            position: absolute
        
            top: 50%
            transform: translateY(-50%)
            z-index: 99
            right: 1.5rem
            outline: none
            transition: .5s
            em 
                font-size: 34px
                color: #ffff
                transition: .5s
                &:hover 
                    color: #ac2021
        .swiper-btn-next,.swiper-btn-prev
            opacity: 0
            transition: all .2s ease-in-out
            @media (max-width: 480px)
                display: none
        &:hover 
            .swiper-btn-next,.swiper-btn-prev
                opacity: 1