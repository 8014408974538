.cvc-project-detail-3
    .catelogy
        .other-projects
            margin-top: 30px
            h3
                font-size: 16px
                color:  #333333
                width: 100%
                background: #e6e6e6
                padding: 10px 20px  
                margin-bottom: 20px
            .item
                margin-bottom: 60px
                figure
                    .image
                        display: flex
                        justify-content: center
                        align-items: center
                        height: 260px
                        overflow: hidden
                        cursor: pointer
                        img
                            max-width: 100%
                            height: 260px
                            transition: .5s all ease-in-out
                    figcaption
                        text-align: center
                        background: #f4f4f4
                        padding: 10px 0
                        h4
                            a
                                color: #333333
                                overflow: hidden
                                text-overflow: ellipsis
                                line-height: 23px
                                -webkit-line-clamp: 2
                                height: 46px
                                display: -webkit-box
                                -webkit-box-orient: vertical
                &:hover
                    figure
                        .image
                            
                            box-shadow: 0px 0px 1px 0px #a1a1a1
                            img
                                transform: scale(1.1)
                        figcaption
                            h4
                                a
                                    color: #cd1518