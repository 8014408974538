.page-banner
	.swiper-slide
		a
			display: block
			height: 500px
			background-size: cover
			background-repeat: no-repeat
			background-position: center
		@keyframes moveInLeft 
			0% 
				opacity: 0
				transform: translateX(-100px)
			80% 
				transform: translateX(10px)
			100% 
				opacity: 1
				transform: translateX(0)
		.banner-title
			position: absolute
			bottom: 50%
			transform: translateY(50%)
			left: 0
			width: 100%
		@media (max-width: 768px)
			.title 
				padding: 2rem
				.img 
					max-width: 18rem!important
				p 
					font-size: 1.4rem!important
		.title 
			text-align: center
			padding: 2rem 8rem 3rem 2rem
			border-left: 6px solid #731d28
			background: rgba(#000,.5)
			display: inline-block
			animation: moveInLeft 1s ease-out
			.img
				max-width: 50rem
				height: auto
				img 
					object-fit: fill
					width: 100%
					height: 100%
				
			p 
				font-size: 2.6rem 
				color: #ffffff
				text-transform: uppercase
				text-align: left
				line-height: 2.5
				white-space: nowrap

			span
				font-size: 3.6rem
				color: #ffffff
				font-weight: 300

		// @media (max-width: 768px)
		// 	.img 
		// 		max-height: 45rem 
		// 		width: auto
		// 		img 
		// 			width: 100%
		// 			height: 100%
		// 			object-fit: cover
		// 	.title 
		// 		span 
		// 			font-size: 2.4rem
	@media (max-width: 1024px)
		.swiper-slide
			a
				height: 300px
	@media (max-width: 575.9px)
		.swiper-slide
			a
				height: 200px