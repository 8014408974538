.tuyendung-ct
    padding: 60px 0
    .table-v2
        td 
            font-size: 16px
            color: #333333
    h1
        font-size: 30px
        font-weight: 700
        color: map-get($mau, main-b)
        margin-bottom: 7px
        text-transform: uppercase
        @media (max-width: 1024px)
            font-size: 21px
    .brief-content
        margin-bottom: 30px
        table 
            margin-left: 15px 
            width: 100%
            tr 
                td 
                    padding: 1rem 0
                    color: #333333
                .title 
                    width: 30%
                    position: relative
                    @media (max-width: 768px)
                        width: 50%
                    &:after
                        content: ':'
                        display: block
                        position: absolute
                        top: 50%
                        transform: translateY(-50%)
                        right: 1rem
                td:first-child
                    color: #333333!important
    h2
        font-size: 20px
        font-weight: 700
        text-transform: uppercase
        margin-bottom: 10px
        color: #333333
        span
            font-size: 18px
            font-weight: 400
            color: #333333

    ul
        margin-bottom: 30px
        padding-left: 15px
        list-style-type: disc
        li
            color: #333333
            font-size: 16px
    p
        font-size: 18px
        font-weight: 400
        color: #333333

    .buttons
        margin-top: 50px
        margin-bottom: 30px
        display: flex
        align-items: center
        flex-wrap: wrap
        a
            text-decoration: none
            font-size: 16px
            font-weight: 700
            color: white
            display: flex
            align-items: center
            justify-content: center
            text-transform: uppercase
            padding: 15px 50px
            border: 2px solid rgba(0,0,0,0)
            transition: .25s cubic-bezier(.4,0,.2,1)
            @media (max-width: 1200px)
                flex: 0 0 50%
                margin-top: 15px
                margin-bottom: 15px
            @media (min-width: 1200px)
                margin: 0 12px
                &:first-child
                    margin-left: 0
                &:last-child
                    margin-right: 0
            @media (max-width: 992px)
                flex: 0 0 100%
                margin-top: 15px
                margin-bottom: 15px
            &.btn-register
                background-color: #ed1c24
                &:hover
                    background-color: rgba(0,0,0,0)
                    color: #ed1c24
                    border-color: #ed1c24
            &.btn-form
                background-color: map-get($mau, main)
                &:hover
                    background-color: rgba(0,0,0,0)
                    color: map-get($mau, main)
                    border-color: map-get($mau, main)
            &.btn-send
                background-color: map-get($mau, main)
                &:hover
                    background-color: rgba(0,0,0,0)
                    color: map-get($mau, main)
                    border-color: map-get($mau, main)
        .or-text
            font-size: 18px
            font-weight: 400
            color: #999999
            @media (max-width: 1200px)
                width: 50%
                text-align: center
            @media (max-width: 992px)
                width: 100%
                text-align: center
    .tools
        display: flex
        align-items: center
        a
            margin-right: 15px
            text-decoration: none
            &:last-child
                margin-right: 0
            &:hover
                em, span
                    color: map-get($mau, main)
        em, span
            color: #999999
            font-size: 20px
            transition: .25s cubic-bezier(.4,0,.2,1)

    .other-news-title
        font-size: 18px
        font-weight: 700
        text-transform: uppercase
        color: white
        background: #0e76bc
        width: 100%
        height: 50px
        padding: 0 20px
        display: flex 
        align-items: center
        line-height: 22px
        @media (max-width: 992px)
            margin-top: 30px

    .other-news
        background-color: #eceff7
        padding: 0 20px
        .item
            padding: 18px 0
            border-bottom: 1px solid #cccccc
            &:last-child
                border-bottom: 0
            .time
                font-size: 13px
                font-weight: 400
                color: #999999
            .title
                text-decoration: none
                font-size: 18px
                font-weight: 700
                color: #333333
                line-height: 20px
                transition: .25s cubic-bezier(.4,0,.2,1)
                &:hover
                    color: map-get($mau, main)
.news-detail-page
    .fancybox-container 
        .fancybox-inner
            .fancybox-content
                padding: 30px 10px!important
                width: 50%!important
                height: 80%
                @media (max-width: 768px)
                    width: 100%!important
                    
            .popup-body
                iframe 
                    width: 100%
                    height: 700px
.news-detail-page
    .Module-245
        display: none
    .canhcam-job-2
        display: none
.Module-283
    .tagline
        display: none!important
