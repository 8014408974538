.cvc-project-detail-2
    .catelogy
        .used-products
            margin-top: 30px
            // border: 1px solid #e6e6e6
            
            h3
                font-size: 16px
                color:  #333333
                width: 100%
                background: #e6e6e6
                padding: 10px 20px
                
            .box
                margin-top: 20px
                .swiper-container
                    width: 100%
                    .swiper-slide
                    
                        .product-item 
                            margin: 0
                        .title
                            font-size: 16px
                        .info-hover
                            h4 
                                font-size: 16px!important
                .product-item 
                    @media (max-width: 768px)
                        .imgbox
                            height: 230px
                    @media (max-width: 576px)
                        .imgbox
                            height: 320px