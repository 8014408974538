.thuong-hieu-ct
    .image
        display: flex
        justify-content: center
        align-items: center
        img
            width: 300px!important
            height: auto!important
    .content
        box-shadow: none!important
    .main-content
        padding: 0 30px
        box-shadow: 0 5px 15px 0 rgba(0,0,0,.08)
    .other-wrapper
        margin-top: 30px
        h4
            font-size: 24px
            margin-bottom: 20px
    .brand-list-wrapper
        // margin: 0 -4px
        display: flex
        flex-wrap: wrap
        border-top: 1px solid #f2f2f2
        border-left: 1px solid #f2f2f2
        // .swiper-container
        //     padding: 15px
        //     margin: -15px
        // justify-content: center
        .item
            // margin: -.5px
            transition: .4s ease-in-out all
            text-align: center
            flex: 0 0 50%
            border-right: 1px solid #f2f2f2
            border-bottom: 1px solid #f2f2f2
            .ic
                display: flex
                justify-content: center
                align-items: center
                img
                    max-height: 150px
            a
                display: block
                padding: 20px
            p
                font-size: 16px
                font-weight: 600
            &:hover
                color: #f90707
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15)

    @media(min-width: 576px)
        .main-content
        .brand-list-wrapper
            .item
                flex: 0 0 33.3333%
    @media(min-width: 768px)
        .main-content
        .brand-list-wrapper
            .item
                flex: 0 0 25%
    @media(min-width: 1025px)
        .main-content
        .brand-list-wrapper
            .item
                flex: 0 0 20%
    @media(min-width: 1200px)
        .main-content
        .brand-list-wrapper
            .item
                flex: 0 0 16.666667%