.product-detail
	.main-title
		border-bottom: 1px solid #e3e3e3
		padding-bottom: 18px
		margin-bottom: 20px!important
	.product-image-slider
		display: flex
		flex-wrap: wrap
		align-items: center
		position: relative
		padding: 20px 0
		img
			width: 100%
	.product-thumbnails-nav
		span
			position: absolute
			width: 25px
			height: 25px
			left: 30px
			font-size: 16px
			display: flex
			justify-content: center
			align-items: center
			cursor: pointer
			&:active, &:focus
				outline: none
		.lnr-chevron-up
			top: -5px
		.lnr-chevron-down
			bottom: -5px
		@media (max-width: 767.9px)
			span
				left: 25px
	.product-images
		max-width: 100%
		flex-grow: 1
		flex-basis: 0
		height: 340px
		.swiper-slide
			.imgbox
				height: 100%
				display: flex
				justify-content: center
				align-items: center
				a
					display: inline!important
			img
				width: auto
				height: 100%
				@media (max-width: 575.9px)
					height: auto
					width: 100%
	.product-thumbnails
		max-width: 85px
		flex: 0 0 85px
		margin-right: 20px
		@media (max-width: 767.9px)
			max-width: 75px
			flex: 0 0 75px
		.swiper-slide
			.imgbox
				border: 1px solid transparent
		.swiper-slide-active
			.imgbox
				border: 1px solid map-get($mau, main-r)
	.brief-content
		font-size: 16px
		font-weight: 400
		padding-bottom: 16px
		color: #333333
	.product-attributes
		table
			width: 100%
			color: #333333
			font-size: 16px
			tr
				border-bottom: 1px dashed #e1e1e1
				&:first-child
					border-top: 1px dashed #e1e1e1
				td
					height: 40px
				th
					font-weight: 600
					width: 50%
				a
					// margin-left: 20px
					padding: 5px 10px
					font-weight: 500
					white-space: nowrap
					font-size: 14px
					background: #ec2224
					color: #ffffff
					&:hover 
						border-radius: 25px
		.code 
			tr 
				border-bottom: none!important
	.contact
		display: flex
		align-items: center
		margin: 20px 0
		.button
			display: flex
			margin-right: 30px
			padding: 5px 30px
			text-transform: uppercase
			font-weight: 500
			white-space: nowrap
			font-size: 16px
			&--1 
				border: 1px solid #ec2224
				color: #ec2224
				&:hover 
	
					background: #ec2224 
					color: #ffffff

			&--2
				border: 1px solid #0e76bc
				color: #0e76bc
				a 
					color: #0e76bc
				span 
					color: #0e76bc
				&:hover
					background: #0e76bc
					color: #ffffff
					a 
						color: #ffffff
					span 
						color: #ffffff
	.product-detail-title
		border-bottom: 1px solid #e3e3e3
		padding-bottom: 8px
		display: flex
		margin: 24px 0
		h5
			font-size: 24px
			font-weight: 600
			color: map-get($mau, main-b)
			text-transform: uppercase
			position: relative
		ul 
			list-style: none 
			display: flex
			li
				margin-right: 20px
				font-size: 16px
				font-weight: 600
				color: map-get($mau, main-b)
				text-transform: uppercase
				position: relative
				&:after
					position: absolute
					width: 0
					transition: .2s all
					height: 1px
					left: 0
					background: map-get($mau, main-b)
					content: ''
					bottom: -8px
				&:hover,&.current
					&:after 
						width: 100%
		
	.product-info
		margin-top: 25px
		font-size: 16px
		overflow: hidden
		position: relative
		&:before
			content: ''
			position: absolute
			width: 100%
			height: 100px
			left: 0
			bottom: 0
			background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.25) 100%)
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1)
		&.show
			height: auto
			&:before
				content: none
		.main-content-attr
			.product-attributes
				width: 100%
				pointer-events: none
				opacity: 0
				top: 0
				z-index: 1
				display: none
				&.current
					pointer-events: auto
					opacity: 1
					top: 0
					z-index: 11
					display: block
			table 
				tr 
					th 
						width: 25%

		.btn-wrapper
			text-align: center
			// margin-top: -160px
			position: relative
			pointer-events: auto
			z-index: 11

		// .full-content
			// max-height: 260px
			// overflow: hidden
			// position: relative
			// &:before
			// 	content: ''
			// 	position: absolute
			// 	width: 100%
			// 	height: 100px
			// 	left: 0
			// 	bottom: 0
			// 	background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.25) 100%)
			// 	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1)
			// &.show
			// 	max-height: unset
			// 	&:before
			// 		content: none
	
	.product-used-by-projects
		margin-top: 50px
		position: relative
		.product-used-by-projects-nav
			span
				margin: 0 8px
				font-size: 24px
				&:focus
					outline: none
			@media (min-width: 1025px)
				position: absolute
				top: -3px
				right: 0
			@media (max-width: 1024px)
				span
					position: absolute
					top: 50%
					z-index: 2
					background: rgba(map-get($mau, main-r),.5)
					width: 35px
					height: 35px
					display: flex
					justify-content: center
					align-items: center
				.lnr-arrow-right
					right: 0
				.lnr-arrow-left
					left: 0
	.product-others
		margin-top: 50px
		position: relative
		.product-others-nav
			
			span
				margin: 0 8px
				font-size: 24px
				&:focus
					outline: none
			@media (min-width: 1025px)
				position: absolute
				top: -3px
				right: 0
			@media (max-width: 1024px)
				span
					position: absolute
					top: 50%
					z-index: 2
					background: rgba(map-get($mau, main-r),.5)
					width: 35px
					height: 35px
					display: flex
					justify-content: center
					align-items: center
				.lnr-arrow-right
					right: 0
				.lnr-arrow-left
					left: 0
@media (max-width: 768px)
	.product-others-nav
		position: absolute
		bottom: 10px
		left: 50%
		transform: translateX(-50%)
		width: 100px
.product-detail-page
	.col-left
		max-width: 100%
		flex: 0 0 100%