.cvc-project-detail-1
    padding-top: 30px
    padding-bottom: 20px
    .image
        img
            width: 100%
            max-height: 100%
    .info-detail
        padding: 20px
        h2
            padding: 10px 0
            font-size: 24px
            color: #333333
            font-weight: 400
        p
            font-size: 16px
            color: #333333
@media(min-width: 768px)
    .cvc-project-detail-1
        padding-top: 50px
        padding-bottom: 60px
        .info-detail
            padding: 0
            h2
                padding-top: 0
                font-size: 30px
                // line-height: 0.8
                padding-bottom: 
@media(min-width: 1025px)
    .cvc-project-detail-1
        padding-bottom: 30px
        .info-detail
            h2
                padding-bottom: 20px