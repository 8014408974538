.home-about 
    text-align: center
    padding: 7rem 0
    h1 
        color: #333333
    .box-video 
        display: flex
        justify-content: center
        padding-bottom: 3rem
        .video
            width: 45rem
            height: 25rem
            position: relative
            &:after 
                position: absolute
                content: ''
                top: 0 
                left: 0 
                width: 100%
                height: 100% 
                background: rgba(#000000,.6)
                transform: scale(1)
            img 
                width: 100%
                height: 100%
                object-fit: cover
            @media (max-width: 480px)
                width: 100%
            .button
                position: absolute
                z-index: 9
                top: 50%
                left: 50%
                transform: translate(-50%,-50%)
            &:hover 
                &:after 
                    transform: scale(0)
                    opacity: 0
                    transition: opacity .3s, transform .5s  
    p
        padding-bottom: 3rem
        font-size: 2.4rem
        color: rgb(51, 51, 51)
        line-height: 1.458
        font-weight: 300
    .btn-view-more
        a 
            color: #ffffff!important
        &:hover 
            a
                box-shadow: 0 5px 5px 0 rgba(0,0,0,.2)
    strong 
        color: #666666




