.side-menu
	background: #f8f8f8
	// padding-bottom: 40px
	.side-menu-list
		padding-top: 15px
	h3

		font-size: 15px
		font-weight: 600
		display: flex
		align-items: center
		padding: 0px 20px 20px
		color: #333333
		cursor: pointer
		position: relative
		span 
			position: absolute
			top: 0px 
			right: 10px
		.lnr
			margin-left: auto
			font-size: 14px
		&:hover 
			a
				color: #ec2224
	ul
		padding: 16px 48px
		// border-bottom: 1px solid #ededed
		font-size: 14px
		color: #666666
		display: none
		list-style: none
		li
			a
				display: block
				padding: 3px 0
				font-size: 15px
			&.active, &:hover
				a
					color: map-get($mau, main-r)
	.side-menu-item
		&.active
			h3
				border-bottom: 2px solid #0e76bc
				color: #0e76bc
				span
					transform: rotate(180deg)
			ul
				display: block
	@media (min-width: 1025px)
		.side-menu-list
			display: block!important
		.side-menu-mobile-header
			display: none
	@media (max-width: 1024px)
		.side-menu-list
			display: none
		.side-menu-mobile-header
			display: flex
			justify-content: space-between
			padding: 10px 15px
			align-items: center
			margin-bottom: 20px
			text-transform: uppercase
			h3 
				padding: 0!important
			.mdi
				margin-left: auto
				font-size: 20px
				color: map-get($mau, main-r)
		