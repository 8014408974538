.thuong-hieu
    h1 
        font-weight: 700
    .img 
        height: 400px
        width: 100%
        img
            width: 100%
            height: 100%
            object-fit: scale-down
    .content 
        padding: 30px
        span  
            font-size: 18px 
            font-weight: 600
        p 
            padding: 5px 0
    .other-brand
        padding: 60px 0
        position: relative
        z-index: 2
        .title
            h1
                color: #333333
                text-transform: uppercase
                text-align: center
        .slider
            .doitac-slider
                position: relative
                .swiper-btnnext
                    margin-left: 10px
                    position: absolute
                    top: 30px
                    right: 30px
                    outline: none
                .swiper-btnprev
                    margin-right: 20px
                    position: absolute
                    top: 30px
                    right: 30px
                    outline: none
            .khachhang-slider
                position: relative
                .swiper-btn-next
                    position: absolute
                    top: 30px
                    right: 30px
                    outline: none
                .swiper-btn-prev
                    margin-right: 20px
                    position: absolute
                    top: 30px
                    right: 30px
                    outline: none
            h4 
                padding: 30px 0
                position: relative
                font-size: 16px
                color: #0e76bc
                &:after
                    position: absolute
                    bottom: 20px
                    content: ''
                    display: block
                    width: 100%
                    height: 1px
                    background: #ebebeb
            .item
                background: #ffffff
                width: 150px
                height: 70px
                display: flex
                justify-content: center
                align-items: center
    @media (max-width: 768px)
        .other-brand
            padding: 40px 0
            .slider
                .doitac-slider
                    flex: 0 0 100%
                    max-width: 100%
                .khachhang-slider
                    flex: 0 0 100%
                    max-width: 100%
                .doitac-slider,.khachhang-slider
                    .swiper-slide
                        display: flex
                        justify-content: center
.nikko-brand
    padding: 60px 0
    .main-title
        text-align: center
        font-weight: 700!important
    .main-wrapper
        border-radius: 5px
        background-color: rgb(255, 255, 255)
        box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.05)
        .heading-wrapper
            padding: 10px 20px
            // border-bottom: 1px solid #f2f2f2
            h2
                margin-bottom: 0
                
        .brand-list-wrapper
            // margin: 0 -4px
            display: flex
            flex-wrap: wrap
            border-top: 1px solid #f2f2f2
            border-left: 1px solid #f2f2f2
            // justify-content: center
            .item
                // margin: -.5px
                transition: .4s ease-in-out all
                text-align: center
                flex: 0 0 50%
                border-right: 1px solid #f2f2f2
                border-bottom: 1px solid #f2f2f2
                .ic
                    display: flex
                    justify-content: center
                    align-items: center
                    img
                        max-height: 150px
                a
                    display: block
                    padding: 20px
                p
                    font-size: 16px
                    font-weight: 600
                &:hover
                    color: #f90707
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15)

    @media(min-width: 576px)
        .main-wrapper
            .brand-list-wrapper
                .item
                    flex: 0 0 33.3333%
    @media(min-width: 768px)
        .main-wrapper
            .brand-list-wrapper
                .item
                    flex: 0 0 25%
    @media(min-width: 1025px)
        .main-wrapper
            .brand-list-wrapper
                .item
                    flex: 0 0 20%
    @media(min-width: 1200px)
        .main-wrapper
            .brand-list-wrapper
                .item
                    flex: 0 0 16.666667%