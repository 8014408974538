.pp-home-team 
    
    padding: 7rem 0
    h1 
        color: #ce1b22
        text-align: center
    .team-slider 
        position: relative
        .swiper-container
            .swiper-wrapper 
                .swiper-slide 
                    display: flex
                    align-items: center
                    justify-content: center
                    
                    .item 
                        display: block
                        outline: none
                        .img 
                            width: 30rem
                            height: 30rem 
                            border: 1.5rem solid #e3e3e3
                            border-radius: 50%
                            img 
                                width: 100%
                                height: 100%
                                object-fit: cover
                                border-radius: 50%
                        .title 
                            text-align: center
                            h3 
                                font-size: 2.4rem 
                                color: #3d6596
                                text-transform: capitalize
                                margin: 3rem 0 .5rem
                                font-weight: 400
                                span 
                                    text-transform: uppercase
                            p 
                                color: #666666
                                text-transform: capitalize 
                                font-size: 2.4rem
                                font-style: italic
                                font-weight: 300
                            
                        &:hover 
                            .img 
                                border: 1.5rem solid #ce1b22
                            .title 
                                h3 
                                    color: #ce1b22
        .team
            position: absolute
            top: 50%
            transform: translateY(-50%)
            outline: none
            em 
                font-weight: 600 
                color: #ffffff
                font-size: 4rem
            &--next 
                right: 0rem
            &--prev
                left: 0rem
.home-page 
    #modal,#modal1,#modal2,#modal3,#modal4,#modal5
        padding: 0
    .fancybox-close-small
        svg 
            color: #ffffff
    .popup-wrapper
        display: flex 
        flex: 0 0 100%
        max-width: 100%
        .img 
            max-width: 30%
            flex: 0 0 30%
            background: #402f1e
            img 
                width: 100%
        .content
            flex: 0 0 70%
            max-width: 70%
            .head 
                background: #402f1e
                width: 100%
                padding: 30px 20px

                h3 
                    font-size: 2.4rem 
                    color: #e5b57e
                    text-transform: uppercase
                    margin-bottom: 1.5rem
                p 
                    color: #ffffff
                    text-transform: capitalize 
                    font-size: 1.8rem
            .full-content
                background: #ffffff
                padding: 30px 20px
                color: #333333 
                font-size: 1.6rem
                p 
                    padding-bottom: 1rem
