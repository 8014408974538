*,*:before,*:after
	padding: 0
	margin: 0
	box-sizing: inherit
main
	overflow: hidden
.title-h1
	font-size: 3.6rem
	text-transform: uppercase
	color: #333333
	text-align: center
	padding-bottom: 2.7rem
	// @media screen and(max-width:1025px)
	// 	font-size: 36px
	// @media screen and(max-width:768px)
	// 	font-size: 26px
	// 	padding-bottom: 1.7rem

img
	max-width: 100%
body
	font-family: Source Sans Pro, sans-serif
	background-color: #ffff
	text-rendering: optimizeLegibility
	box-sizing: border-box
	font-size: 16px
html
	font-family: Source Sans Pro, sans-serif
	background-color: #ffff
	text-rendering: optimizeLegibility
	font-size: 62.5%
body
	&.over-hidden
		overflow: hidden
main
	// padding-top: 120px
	// @media (max-width: 1199.9px)
	// 	padding-top: 100px
a
	color: inherit
	text-decoration: none
.clearfix:after
	clear: both
	content: ""
	display: block
.backdrop
	position: fixed
	top: 100px
	left: 0
	width: 100%
	bottom: 0
	background: rgba(black,.8)
	z-index: 9
	transform: translateX(-100%)
	transition: all .4s cubic-bezier(.2,.5,.5,.8)
	opacity: 0
	&.open
		transform: translateX(0)
		opacity: 1
.pd-60
	padding: 60px 0
	@media (max-width: 1024px)
		padding: 40px 0
.pb-60
	padding-bottom: 60px
	@media (max-width: 1024px)
		padding-bottom: 40px
.pt-60
	padding-top: 60px
	@media (max-width: 1024px)
		padding-top: 40px

.main-title
	font-size: 36px
	font-weight: 600
	text-transform: uppercase
	color: map-get($mau, main-b)
	margin-bottom: 30px
	line-height: 42px
	@media (max-width: 1199.98px)
		font-size: 32px
	@media (max-width: 1024px)
		font-size: 28px
	@media (max-width: 575.9px)
		font-size: 24px
	&.center
		text-align: center

// các Loại nút
.btn-viewmore
	display: inline-block
	padding: 5px 7px
	font-weight: 500
	.lnr
		margin-left: 10px
	&.white
		color: white
		border-bottom: 1px solid white

	&.red
		color: map-get($mau, main-r)
		border-bottom: 1px solid map-get($mau, main-r)
	&:hover
		text-decoration: none

.red-square
	position: relative
	&:after,&:before
		content: ''
		position: absolute
		border-top: 15px solid transparent
		border-bottom: 15px solid transparent
		top: 0%
		transform: translateY(-50%)
		z-index: 1
	&:before
		border-right: 15px solid map-get($mau, main-r)
		right: 50%
	&:after
		border-left: 15px solid map-get($mau, main-r)
		left: 50%
.responsive-table
	display: block
	width: 100%
	overflow: auto
.table-v2
	width: 100%
	border-collapse: collapse
	tr
		td
			font-size: 15px
			color: #666666
			padding: 10px 0
			&:first-child
				font-weight: 700
				@media (min-width: 1400px)
					width: 200px
				@media (max-width: 992px)
					width: 200px
			&:last-child
				font-weight: 400

.cmsadminpanel
	right: unset
	left: 0
.btn-view-more
	a
		padding: 1rem 3rem
		background: #ce1b22
		border-radius: 25px
		color: #ffffff
		font-size: 1.8rem
		position: relative
		font-weight: 600
		img
			margin-right: .5rem
			margin-bottom: .3rem

.alert-info
	position: relative
	padding: .75rem 1.25rem
	margin-bottom: 1rem
	border: 1px solid transparent
	border-radius: .25rem
	color: #0c5460
	background-color: #d1ecf1
	border-color: #bee5eb
	font-size: 24px
	text-align: center

.announce
	position: relative
	height: 100%
	width: 100%
	transform: translateY(100%)
.homepage
	.cvc-footer
		footer
			.top-footer
				display: none
.modal-about
	max-width: 820px
	margin: 0 auto
	padding: 0 !important
	position: relative
	.button-close
		position: absolute
		display: flex
		justify-content: center
		align-items: center
		right: 0
		top: 0
		z-index: 11
		cursor: pointer
		background: #1173b8
		width: 30px
		height: 30px
		p
			font-size: 26px
			font-weight: 400
			color: #ffffff
			line-height: 1
	.main-content
		height: 600px
		overflow-y: scroll
		padding: 15px
		.image
			height: 250px
			width: 100%
			overflow: hidden
			position: relative
			margin-bottom: 20px
			img
				width: 100%
				height: 100%
				position: absolute
				object-fit: cover
		.sub-title
			padding-bottom: 5px
			h4
				font-size: 16px
				font-weight: 400
				color: #333333
		.title
			padding-bottom: 15px
			position: relative
			margin-bottom: 25px
			h3
				font-size: 24px
				font-weight: 700
				color: #1173b8
			&:before
				content: ''
				position: absolute
				bottom: 0
				left: 0
				background: #1173b8
				width: 74px
				height: 2px
		.content
			font-size: 16px
			font-weight: 400
			color: #333333
			p
				margin-bottom: 15px
	@media(min-width: 1025px)
		width: 820px
		.main-content
			padding: 0
			display: flex
			justify-content: center
			align-items: flex-start
			overflow-y: hidden
			height: 610px
			padding-right: 5px
			.image
				flex: 0 0 49%
				max-width: 49%
				height: 610px
				margin-bottom: 0
			.full-content
				flex: 0 0 51%
				max-width: 51%
				padding: 35px
				padding-right: 5px
				.menu-scroll
					min-height: 500px
					max-height: 540px
					overflow-y: auto
					padding-right: 30px
					&::-webkit-scrollbar
						width: 4px
					&::-webkit-scrollbar-track
						background: #1173b8
					&::-webkit-scrollbar-thumb
						background: #ebebeb
					&::-webkit-scrollbar-thumb:hover
						background: #ebebeb