.product-item
	position: relative
	overflow: hidden
	margin-bottom: 30px
	&:before
		position: absolute
		width: 100%
		height: 100%
		top: 0
		left: 0
		opacity: 0
		transition: all .4s cubic-bezier(.2,.5,.5,.8)
		content: ''
		+bg-gradient-1
		@media (max-width: 1024px)
			content: none
	.imgbox
		overflow: hidden
		width: auto
		height: 180px
		img
			width: 100%
			height: 100%
			object-fit: fill
	.title
		text-transform: uppercase
		text-align: center
		transition: all .4s cubic-bezier(.2,.5,.5,.8)
		padding: 18px 10px
		@media (max-width: 1024px)
			display: none
		h4
			font-size: 16px
			font-weight: 700
			text-transform: uppercase
			overflow: hidden
			text-overflow: ellipsis
			line-height: 25px
			-webkit-line-clamp: 2
			height: 50px
			display: -webkit-box
			-webkit-box-orient: vertical
		p
			font-size: 14px
			font-weight: 500
			color: #666666
			margin-bottom: 5px
			max-height: 42px
			overflow: hidden
	.info-hover
		text-align: center
		h4
			margin-bottom: 24px
			max-height: 72px
			overflow: hidden
			font-size: 18px
			font-weight: 700
			overflow: hidden
			text-overflow: ellipsis
			line-height: 25px
			-webkit-line-clamp: 2
			height: 50px
			display: -webkit-box
			-webkit-box-orient: vertical
			color: #ffffff
			a
				color: white
				text-transform: uppercase
		p
			font-size: 14px
			font-weight: 400
			color: white
			max-height: 42px
			overflow: hidden
			text-transform: uppercase
			margin-bottom: 5px
		@media (max-width: 1024px)
			h4
				max-height: 60px
				font-size: 20px
				margin-bottom: 10px
				a
					color: #333333
			p
				margin-bottom: 10px
				color: #666666
				font-size: 13px
				max-height: 39px
		@media (max-width: 575.9px)
			h4
				max-height: 48px
				font-size: 16px
			p
				font-size: 12px
				max-height: 36px
		@media (min-width: 1025px)
			padding: 20px 25px
			position: absolute
			top: 50%
			left: 0
			right: 0
			width: unset
			z-index: 2
			display: flex
			align-items: center
			justify-content: center
			flex-wrap: wrap
			text-align: center
			transform: scale(.5) translateY(-50%)
			opacity: 0
			transition: all .4s cubic-bezier(.2,.5,.5,.8)
			h4
				width: 100%
	.btn-viewmore
		font-size: 14px
		@media (max-width: 1024px)
			color: #333333
			border-bottom: 1px solid #333333
	
	&:hover
		&:before
			opacity: .8
		.title
			opacity: 0
			transform: translateY(100%)
		.info-hover
			transform: scale(1) translateY(-50%)
			opacity: 1
	.btn-viewmore:hover
		color: #fff100
		border-bottom: 1px solid #fff100
