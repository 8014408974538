.lienhe
	.tagline
		display: flex
		align-items: center
		justify-content: center
		margin-bottom: 30px
	h1
		font-size: 20px
		font-weight: 500
		line-height: 40px
		text-transform: uppercase
		color: #333333
		position: relative
		@media (min-width: 992px)
			font-size: 36px
			line-height: 55px
		&:before
			position: absolute
			content: ''
			bottom: 0
			left: 50%
			transform: translateX(-50%)
			width: 38%
			height: 2px
			background-color: map-get($mau, main-b)

	.contact-map
		.items-wrapper
			border: 1px solid #e5e5e5
			padding: 20px
			
		.items
			height: 470px
			overflow-y: auto
			
			&::-webkit-scrollbar
				width: 5px

			&::-webkit-scrollbar-track
				background: #e1e1e1

			&::-webkit-scrollbar-thumb
				border-radius: .25rem
				background: linear-gradient(225deg, map-get($mau, main-b) 0%, map-get($mau, main-b) 100%)
		.item
			width: 100%
			background-color: rgba(0,0,0,0)
			padding: 20px
			margin-top: 20px
			border-top: 1px solid #e5e5e5
			cursor: pointer
			transition: .25s cubic-bezier(.4,0,.2,1)
			&:first-child
				margin-top: 0
				border-top: 0
			&:hover, &.active
				background-color: #eceff7
				h2
					color: map-get($mau, main-b)
			h2
				font-size: 15px
				font-weight: 700
				color: map-get($mau, main-b)
				text-transform: uppercase
				line-height: 22px
			p, a
				display: flex
				align-items: flex-start
				font-size: 14px
				line-height: 22px
				font-weight: 400
				margin: 8px 0
			em
				font-size: 18px
				color: map-get($mau, main-b)
				margin-right: 15px
			p
				color: #333333
			a
				text-decoration: none
				color: map-get($mau, main-b)
				transition: .25s cubic-bezier(.4,0,.2,1)
				&:hover
					color: map-get($mau, main-b)

		@media (max-width: 992px)
			display: block
		@media (min-width: 992px)
			display: flex
			justify-content: center
			.items-wrapper
				max-width: 33.333%
				flex: 0 0 33.333%
		.map
			width: 100%
			height: auto
			@media (max-width: 992px)
				display: none
			iframe
				width: 100%
				height: 100%

@media (max-width: 992px)
	.contact-map-popup 
		#contactMap
			width: 100%
			height: 80%
			padding: 0
			iframe
				width: 100%
				height: 100%


.form-contact-v1
	padding: 30px
	background-image: url('../img/bg_contact.jpg')
	background-size: cover
	background-position: center
	@media (min-width: 992px)
		padding: 60px 100px
		padding-bottom: 110px
	h2 
		font-size: 18px
		font-weight: 400
		color: #333333
		line-height: 30px
		text-align: center
		margin-bottom: 20px
	

	.wrap-form
		margin-top: 30px
		@media(min-width: 1025px)
			margin-top: 0
		input[type='text'], textarea
			width: 100%
			padding: 12px 20px
			font-size: 15px
			border-radius: 5px
			border: 1px solid #e9e9e9
			color: #333333
			caret-color: #ec2224
			&:placeholder
				color: #999999
			&:focus
				outline: none
				border-color: #ec2224
				box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3)
		textarea
			height: 180px
		&>div
			display: flex
			flex-wrap: wrap
			margin: 0 -5px
			.qtext
				padding: 0 5px
				flex: 0 0 50%
				margin-bottom: 20px
				@media(max-width: 768px)
					flex: 0 0 100%
			.qparagraph
				flex: 0 0 100%
				padding: 0 5px
				margin-bottom: 20px
			span
				color: red
				font-size: 13px
		.frm-captcha
			display: flex
			align-items: center
			flex-direction: row-reverse
			justify-content: flex-end
			display: flex
			.frm-captcha-input
				width: calc(100% - 227px)
				label
					display: none
			.RadCaptcha
				width: auto!important
				position: relative
				span
					position: absolute
					bottom: 100%
				&>div>div
					align-items: center
					display: flex
					width: 227px
			@media (max-width: 768px)
				.frm-captcha-input
					width: calc(100% - 140px)
					padding-left: 40px
				.RadCaptcha
					&>div>div
						align-items: center
						display: flex
						width: 140px
			.rcRefreshImage
				margin: 0 10px
				font-size: 0
				&:after
					content: url('/Data/Sites/1/media/refresh.png')
		.frm-btnwrap
			// margin-top: 20px
			display: flex
			label
				display: none
			.frm-btn-reset
				display: none
			.frm-btn
				width: 100%
				display: flex
			@keyframes jelly 
				0%,100% 
					transform: scale(1, 1) 
				25% 
					transform: scale(0.9, 1.1) 
				50% 
					transform: scale(1.1, 0.9) 
				75% 
					transform: scale(0.95, 1.05)
			input[type="submit"]
				margin-left: auto
				display: block
				width: 180px
				height: 48px
				display: flex
				justify-content: center
				align-items: center
				border-radius: 15px
				font-size: 14px
				border: none
				color: #ffffff
				text-transform: uppercase
				background: #ff9900
				em 
					padding-left: 10px
				&:hover 
					cursor: pointer
					animation: jelly 0.5s
		@media(min-width: 768px)
			.frm-captcha
				width: 75%
				float: left
			.frm-btnwrap
				float: left
				margin-top: 0
				width: 25%
				margin-left: 10px
		@media (max-width: 768px)
			.frm-btnwrap
				
				width: 100%
				flex: 0 0 100%
				justify-content: center
				.frm-btn
					padding-top: 20px!important
					width: 100%
					flex: 0
					justify-content: center

.form-contact-v2
	padding: 30px
	background-image: url('../img/bg_contact.jpg')
	background-size: cover
	background-position: center
	@media (min-width: 992px)
		padding: 50px
	h2 
		font-size: 18px
		font-weight: 400
		color: #333333
		line-height: 30px
		text-align: center
		margin-bottom: 20px
	.form-group
		margin-bottom: 20px
	input[type='text'], textarea, select
		width: 100%
		height: 50px
		padding: 0 20px
		color: #333333
		border: 1px solid #e5e5e5
		box-shadow: none
		outline: none
	textarea
		height: 200px
		resize: none
		padding: 20px
	input[type='reset']
		display: none
	.wrap-form>div
		display: flex
		flex-wrap: wrap
		margin: 0 -10px
	.form-group.frm-captcha
		margin-bottom: 0
		.frm-captcha-input
			@media (min-width: 992px)
				float: left
				width: 250px
		.RadCaptcha
			float: left
			width: 210px !important
			position: relative
			> span
				position: absolute
				font-size: 0
				display: flex
				align-items: center
				justify-content: center
				left: -30px
				top: 50%
				transform: translateY(-50%)
				&:before
					display: inline-block
					font-family: 'Material Design Icons'
					content: '\f026'
					font-size: 16px
					color: #ff0000
			> div > div
				display: flex
				flex-direction: row-reverse
				align-items: center
				justify-content: flex-end
				.rcRefreshImage
					font-size: 0
					position: relative
					display: flex !important
					align-items: center
					justify-content: center
					cursor: pointer
					margin-left: 4px
					margin-right: 4px
					text-decoration: none
					&:before
						height: 100%
						font-family: 'Material Design Icons'
						content: '\f450'
						font-size: 30px
						color: map-get($mau, main-b)

	.form-group.frm-btnwrap
		> label
			display: none
		@media (min-width: 992px)
			margin-bottom: 0
			width: 100%
		.frm-btn
			height: 100%
			max-width: 100%
			flex-grow: 1
			flex-basis: 0
			@media (min-width: 992px)
				display: flex
				align-items: center
				justify-content: center
			input[type="submit"]
				font-size: 15px
				font-weight: 700
				color: white
				box-shadow: none
				outline: none
				display: block
				border: 0
				background-color: #ed1c23
				text-transform: uppercase
				width: 100%
				height: 50px
				display: flex
				align-items: center
				justify-content: center
				cursor: pointer
				@media (min-width: 992px)
					width: 200px
					transition: .25s cubic-bezier(.4,0,.2,1)
					&:hover
						background-color: #ed1c23
						color: white