.giai-thuong-wrapper 
    background: #3d6596
    padding: 7rem 0
    h1 
        text-align: center 
        color: #ffffff!important
    .slider
        position: relative
    .swiper-container 
        .swiper-wrapper 
            .swiper-slide 
                .item 
                    background: #f5f5f5
                    .img 
                        height: 25.6rem
                        width: auto 
                        padding: 30px 
                        display: flex 
                        align-items: center 
                        justify-content: center
                        img
                            height: 100%
                            width: auto 
                            object-fit: fill
                            
                    .title 
                        border-top: 1px solid #f7941d
                        h3
                            padding: 25px 0 
                            text-align: center
                            color: #333333
                            font-size: 1.6rem 
                            font-weight: 600
                            text-align: center 
                            overflow: hidden
                            text-overflow: ellipsis
                            line-height: 24px
                            -webkit-line-clamp: 1
                            height: 24px
                            display: -webkit-box
                            -webkit-box-orient: vertical
    .swiper-navigation
        width: 110%
        display: flex
        align-items: center
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        z-index: 99
        .product-button-next,.product-button-prev
            width: 3rem
            height: 3rem
            display: flex
            border: 1px solid #d7d7d7
            outline: none
            display: flex
            justify-content: center
            align-items: center
            background: #ffffff
            em 
                color: #3d6596
                font-size: 1.8rem
                font-weight: bold 
            &:hover 
                background: #3d6596
                em
                    color: #ffffff
                    
                    transition: all .4s ease-in-out
        .product-button-next 
            margin-left: auto
        @media (max-width: 992px)
            top: 105%
            width: 80px